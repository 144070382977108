import React, { Fragment } from "react"

const Navigation = () => {
  return (
    <ul>
      <li>Free Code Camp Projects</li>
    </ul>
  )
}

export default Navigation
